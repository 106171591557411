<template>
    <div class="body-container">
        <BannerSection title=" Préstamos en línea por tu carro:<br class='d-none d-lg-block'/> rápidos y seguros"
                       subtitle="¡Fácil y rápido! Consigue tu préstamo hoy" />
        <PerksComponent></PerksComponent>
        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <p>¿Necesitas dinero rápido y no quieres complicarte? Con nosotros, tu auto es más que un medio de
                        transporte: es la llave para tu préstamo personal. </p>
                    <p>Obtén financiamiento de manera sencilla, sin necesidad de vender o dejar de manejar tu vehículo.
                    </p>
                    <br>
                    <span class="bold-text">
                        <h3 style="font-size: 20px;">Ventajas de elegirnos</h3>
                        

                    </span>
                    <br>
                    <ul>
                        <li>
                            <p class="mb-0 bold-text"
                               style="font-size: 20px;">
                                Tu auto se queda contigo.
                            </p>
                            <span>No tienes que preocuparte por cambiar la titularidad de tu auto. Lo usas como siempre,
                                incluso durante el préstamo.</span>
                        </li>

                        <br>
                        <li>
                            <p class="mb-0 bold-text"
                               style="font-size: 20px;">
                                Todo en línea.
                            </p>
                            <span> ¡No salgas de casa para conseguir tu préstamo! Nuestra plataforma te permite
                                gestionar todo el proceso desde tu computadora o teléfono.</span>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0 bold-text"
                               style="font-size: 20px;">
                                Recibe hasta $120.000 MXN de manera rápida.
                            </p>
                            <span> En solo 48 horas, podrías tener el dinero en tu cuenta. Así de rápido y sin
                                complicaciones.</span>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0 bold-text"
                               style="font-size: 20px;">
                                Si estás en el Buró, no es problema.
                            </p>
                            <span> Sabemos que todos enfrentamos momentos difíciles. Aceptamos solicitudes incluso si
                                tienes mal historial en el Buró de Crédito.</span>
                        </li>

                        <br>
                    </ul>
                    <h3 style="font-size: 20px;"
                        class=" bold-text">¿Necesitas un préstamo seguro y sin dejar tu auto?</h3>
                    <p>Estás en el lugar correcto. Nuestros préstamos personales en línea son rápidos, seguros y te
                        permiten seguir con tu vehículo.</p>
                    <br>
                </div>
            </div>
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <h3 style="font-size: 20px;">Así puedes obtener tu préstamo
                        </h3>
                        
                    </span>
                    <ol>
                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Llena el formulario en línea.
                            </h4>
                            <span>solo te tomará unos minutos. Necesitamos algunos datos de tu auto y tu información
                                personal.</span>
                        </li>

                        <br>
                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Espera tu oferta:
                            </h4>
                            <span>analizaremos tu solicitud y te enviaremos una oferta de préstamo personalizada. Esto
                                no te compromete a nada aún.</span>
                        </li>
                        <br>
                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Envía los documentos:
                            </h4>
                            <span>si te convence nuestra oferta, necesitaremos algunos documentos para continuar. Puedes
                                enviarlos todos en línea.</span>
                        </li>
                        <br>
                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Recibe tu dinero:
                            </h4>
                            <span>una vez que todo esté en orden, transferiremos el dinero a tu cuenta bancaria en menos
                                de dos días.</span>
                        </li>
                        <br>
                    </ol>
                    <p>Conseguir un préstamo nunca fue tan fácil. No te preocupes por papeleos complicados o visitas a
                        oficinas. Todo nuestro proceso es en línea para que puedas gestionarlo desde donde estés, sin
                        interrumpir tu día.</p>
                    <br>
                </div>
            </div>
        </div>

        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <br>
                        <h3 style="font-size: 20px;">¿Qué necesitas para pedir préstamos personales en línea por un
                            carro?
                        </h3>
                        
                    </span>
                    <p>Para avanzar con tu solicitud, necesitamos algunos documentos como:</p>
                    <ul>
                        <li>
                            <span>Tu INE o identificación oficial. Asegúrate de que la copia sea clara y legible.</span>
                        </li>

                        <br>
                        <li>
                            <span>Comprobante de ingresos reciente. Puede ser tu último recibo de nómina o un estado de
                                cuenta que muestre tus ingresos.</span>
                        </li>
                        <br>

                        <li>
                            <span>Comprobante de domicilio para corroborar tu dirección. </span>
                        </li>
                        <br>
                        <li>
                            <span>Documentación completa de tu vehículo. Necesitamos la tarjeta de circulación, fotos
                                del auto y la factura original.</span>
                        </li>
                    </ul>
                    <p>Es fácil, ¿verdad? Solo reúne estos documentos y súbelos a través de nuestro sistema seguro. Todo
                        en línea, sin filas ni esperas.</p>
                    <span class="bold-text">
                        <br>
                        <h3 style="font-size: 20px;">¿Tienes dudas? Las aclaramos aquí
                        </h3>
                       
                    </span>
                    <ul>
                        <li>
                            <h4 style="font-size: 20px;"><b>Quiero pedir un préstamo, pero estoy en el Buró </b></h4>
                            <span>No te preocupes si tienes mal historial en el Buró de Crédito. Entendemos que a veces
                                las cosas no salen como las planeamos. Evaluamos cada caso de forma individual y podemos
                                ofrecerte un préstamo a pesar de tu historial crediticio. </span>
                        </li>
                        <br>
                        <li>
                            <h4 style="font-size: 20px;"><b>¿Qué pasa si decido vender mi auto? </b></h4>
                            <span>Mientras tengas un préstamo activo con nosotros, tu auto sirve como garantía del
                                mismo. Esto significa que no podrás venderlo hasta que se liquide completamente el
                                préstamo. </span>
                        </li>
                        <br>
                        <li>
                            <h4 style="font-size: 20px;"><b>¿Cuánto tiempo tarda el proceso de aprobación? </b></h4>
                            <span>Nos esforzamos por hacer todo lo más rápido posible. Si tienes todos tus documentos en
                                regla y tu solicitud completa, podríamos aprobar tu préstamo y transferir el dinero en
                                menos de 48 horas. </span>
                        </li>
                        <br>
                    </ul>

                    <h3 class="bold-text"
                        style="font-size: 20px;">Solicita préstamos en línea seguros con Ibancar </h3>
                   
                    <h4 style="font-size: 20px;">Con nosotros, tu préstamo es rápido, seguro y sin complicaciones. ¡Tu
                        auto es todo lo que necesitas!
                    </h4>

                    
                </div>
            </div>
        </div>

        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'PrestamosEnLineaPorTuCarro',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Préstamos en línea por un carro | Ibancar',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Solicita un préstamo en línea seguro usando tu carro como aval. Trámites rápidos, incluso con mal buró, y con total seguridad. ¡Recibe tu dinero sin salir de casa!'
                    },
                    {
                        name: 'keywords',
                        content: 'prestamos en linea seguros prestamos personales en linea por un carro'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/prestamos-sin-buro', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.image-container {
    text-align: center;
    margin: 3rem 0rem;

    img {
        max-height: 400px;
    }
}

.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: justify;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>